<template>
  <div class="reward">
    <v-row>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12">
        <Card class="full-height">
          <div slot="body" :class="`pa-${$vuetify.breakpoint.mdAndUp ? 5 : 3}`">
            <v-layout class="pb-3" align-center>
              <v-avatar class="mr-3" color="#0000001a" size="24">
                <v-img
                  width="22"
                  height="22"
                  contain
                  aspect-ratio="2"
                  :src="`${$config.host.image}icon/bifi.png`" />
              </v-avatar>
              <div
                class="reward-description-title"
                v-text="$t('reward.summary.title')" />
            </v-layout>
            <div class="reward-description" v-html="description" />
          </div>
        </Card>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 2 : 12">
        <Card class="full-height">
          <v-layout
            slot="body"
            class="full-height"
            column
            justify-center
            align-center>
            <div
              class="reward-info-key"
              v-text="$t('reward.summary.totalReward')" />
            <v-tooltip
              bottom
              transition="slide-y-transition"
              :disabled="reward.total.lte('0')">
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="reward-info-value"
                  v-text="totalReward"
                  v-bind="attrs"
                  v-on="on" />
              </template>
              <span v-text="totalRewardDetail" />
            </v-tooltip>
          </v-layout>
        </Card>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 2 : 6">
        <Card class="full-height">
          <v-layout
            slot="body"
            class="full-height"
            column
            justify-center
            align-center>
            <div
              class="reward-info-key"
              v-text="$t('reward.summary.dailyReward')" />
            <v-tooltip
              bottom
              transition="slide-y-transition"
              :disabled="reward.daily.lte('0')">
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="reward-info-value"
                  v-text="dailyReward"
                  v-bind="attrs"
                  v-on="on" />
              </template>
              <span v-text="dailyRewardDetail" />
            </v-tooltip>
          </v-layout>
        </Card>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 2 : 6">
        <Card class="full-height">
          <v-layout
            slot="body"
            class="full-height"
            column
            justify-center
            align-center>
            <div
              class="reward-info-key"
              v-text="$t('reward.summary.remainingReward')" />
            <v-tooltip
              bottom
              transition="slide-y-transition"
              :disabled="reward.remaining.lte('0')">
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="reward-info-value"
                  v-text="remainingReward"
                  v-bind="attrs"
                  v-on="on" />
              </template>
              <span v-text="remainingRewardDetail" />
            </v-tooltip>
          </v-layout>
        </Card>
      </v-col>
    </v-row>
    <v-layout class="reward-header" align-end>
      <CardOutterTitle v-t="'reward.title'" />
      <v-spacer />
    </v-layout>
    <v-row>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 8 : 12">
        <Card class="reward-table">
          <CardTable
            slot="body"
            :vertical="$vuetify.breakpoint.smAndDown"
            :nodata="$t('reward.table.nodata')"
            :header="header"
            :rows="marketRows" />
        </Card>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 4 : 12">
        <CardSrd class="reward-my-info">
          <div
            slot="header-title"
            class="reward-my-info-title"
            v-text="$t('reward.mine.title')" />
          <template slot="body">
            <v-tooltip
              bottom
              transition="slide-y-transition"
              :disabled="amount.lte('0')">
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="reward-my-info-value"
                  v-text="myAmount"
                  v-bind="attrs"
                  v-on="on" />
              </template>
              <span v-text="myAmountDetail" />
            </v-tooltip>
            <div class="reward-my-info-sub-value" v-text="myAsset" />
          </template>
          <template slot="footer-button">
            <v-btn
              class="card-srd-footer-button"
              color="#1f7bff"
              depressed
              :disabled="!hasAmount"
              v-text="$t('reward.mine.button')"
              @click="entryTrade" />
          </template>
        </CardSrd>
      </v-col>
    </v-row>

    <DialogTrade
      :type="dialog.type"
      :color="dialog.color"
      :token="dialog.token"
      @syncData="syncData"
      v-model="dialog.show" />
  </div>
</template>

<script>
import CardOutterTitle from '@/components/card/CardOutterTitle';
import Card from '@/components/card/Card';
import CardTable from '@/components/card/CardTable';
import CardSrd from '@/components/card/CardSrd';
import DialogTrade from '@/components/dialog/Trade';

export default {
  name: 'Reward',
  components: {
    CardOutterTitle,
    Card,
    CardTable,
    CardSrd,
    DialogTrade,
  },
  data() {
    return {
      dialog: {
        type: 'reward.claim',
        color: '#1f7bff',
        token: {
          isToken: true,
          id: 'bifi',
          title: 'BiFi',
          handler: {
            address: '',
          },
          maxAmount: {
            'reward.claim': new this.$BBN('0'),
          },
          price: new this.$BBN('1'),
          address: '',
          unitString: 'BiFi',
          replaceString: '{} BiFi',
          image: 'bifi.png',
        },
        show: false,
      },
      reward: {
        total: new this.$BBN('0'),
        daily: new this.$BBN('0'),
        claimed: new this.$BBN('0'),
        remaining: new this.$BBN('0'),
      },
      amount: new this.$BBN('0'),
      marketData: [],
    };
  },
  computed: {
    description() {
      return this.$t('reward.summary.description.title').replace(
        '{link}',
        `<a
            class="reward-description-link"
            rel="noopener"
            target="_blank"
            href="${this.$t('reward.summary.description.link.url')}">${this.$t(
          'reward.summary.description.link.title'
        )}</a>`
      );
    },
    hasAmount() {
      return this.amount.gt('0');
    },
    totalRewardDetail() {
      return this.dialog.token.replaceString.replace(
        '{}',
        this.reward.total.toStr()
      );
    },
    totalReward() {
      return this.$curr.getWordFormatedValue(
        this.reward.total,
        this.$i18n.locale
      );
    },
    dailyRewardDetail() {
      return this.dialog.token.replaceString.replace(
        '{}',
        this.reward.daily.toStr()
      );
    },
    dailyReward() {
      return this.$curr.getWordFormatedValue(
        this.reward.daily,
        this.$i18n.locale
      );
    },
    claimedRewardDetail() {
      return this.dialog.token.replaceString.replace(
        '{}',
        this.reward.claimed.toStr()
      );
    },
    claimedReward() {
      return this.$curr.getWordFormatedValue(
        this.reward.claimed,
        this.$i18n.locale
      );
    },
    remainingRewardDetail() {
      return this.dialog.token.replaceString.replace(
        '{}',
        this.reward.remaining.toStr()
      );
    },
    remainingReward() {
      return this.$curr.getWordFormatedValue(
        this.reward.remaining,
        this.$i18n.locale
      );
    },
    myAmountDetail() {
      return this.dialog.token.replaceString.replace('{}', this.amount.toStr());
    },
    myAmount() {
      return this.$curr.getWordFormatedValue(
        this.amount,
        this.$i18n.locale,
        '{} BiFi',
        2,
        true
      );
    },
    myAsset() {
      return this.$curr.getWordFormatedValue(
        this.$curr.getFormatedTokenValue(
          this.amount,
          'bifi',
          this.$store.getters.currency
        ),
        this.$i18n.locale,
        this.$store.getters.replaceString,
        2,
        true
      );
    },
    header() {
      let result = [];

      if (this.$vuetify.breakpoint.mdAndUp) {
        result = [
          {
            title: this.$t('reward.table.market'),
            colspan: 2,
          },
          {
            image: `${this.$config.host.image}icon/bifi.png`,
            title: this.$t('reward.table.dailyReward'),
            class: 'text-right',
          },
          {
            title: this.$t('reward.table.depositAPY'),
            tooltip: this.$t('reward.table.depositAPYDescription'),
            class: 'text-right',
          },
          {
            title: this.$t('reward.table.borrowAPY'),
            tooltip: this.$t('reward.table.borrowAPYDescription'),
            class: 'text-right',
          },
          {
            title: this.$t('reward.table.maxAPY'),
            tooltip: this.$t('reward.table.maxAPYDescription'),
            class: 'text-right',
          },
        ];
      }

      return result;
    },
    marketRows() {
      let result = [];

      if (this.$vuetify.breakpoint.mdAndUp) {
        result = this.marketData.map((token) => ({
          columns: [
            {
              type: 'avatar',
              image: `${this.$config.host.image}icon/${token.image}`,
              text: token.title,
            },
            {
              type: 'text',
              text: token.title,
            },
            {
              type: 'text',
              text: this.$curr.getWordFormatedValue(
                token.dailyReward,
                this.$i18n.locale,
                undefined,
                2,
                true
              ),
              class: 'text-right',
            },
            {
              type: 'text',
              text: token.deposit.apy.balanceFormat('{}%', 2, 7, true),
              class: 'text-right',
            },
            {
              type: 'text',
              text: token.borrow.apy.balanceFormat('{}%', 2, 7, true),
              class: 'text-right',
            },
            {
              type: 'text',
              text: token.maxAPY.balanceFormat('{}%', 2, 7, true),
              class: 'text-right',
            },
          ],
        }));
      } else {
        result = this.marketData.map((token) => ({
          columns: [
            {
              type: 'avatar-text',
              image: `${this.$config.host.image}icon/${token.image}`,
              text: token.title,
            },
            {
              type: 'columns',
              columns: [
                {
                  text: this.$t('reward.table.dailyReward'),
                },
                {
                  text: this.$t('reward.table.depositAPY'),
                  tooltip: this.$t('reward.table.depositAPYDescription'),
                },
                {
                  text: this.$t('reward.table.borrowAPY'),
                  tooltip: this.$t('reward.table.borrowAPYDescription'),
                },
                {
                  text: this.$t('reward.table.maxAPY'),
                  tooltip: this.$t('reward.table.maxAPYDescription'),
                },
              ],
              class: 'text-left',
            },
            {
              type: 'columns',
              columns: [
                {
                  text: this.$curr.getWordFormatedValue(
                    token.dailyReward,
                    this.$i18n.locale,
                    undefined,
                    2,
                    true
                  ),
                  appendImage: `${this.$config.host.image}icon/bifi.png`,
                },
                {
                  text: token.deposit.apy.balanceFormat('{}%', 2, 7, true),
                },
                {
                  text: token.borrow.apy.balanceFormat('{}%', 2, 7, true),
                },
                {
                  text: token.maxAPY.balanceFormat('{}%', 2, 7, true),
                },
              ],
              class: 'text-right',
            },
          ],
        }));
      }

      return result;
    },
  },
  methods: {
    entryTrade() {
      if (this.$store.getters.isWalletUsable) {
        this.dialog.show = true;
      } else {
        this.$store.dispatch('openWalletConnector', {});
      }
    },
    syncData() {
      this.$store.dispatch('addProgress', {});

      this.$store.getters.contract
        .call('bifi.si', this.$store.getters.walletAddress)
        .then(async (result) => {
          this.dialog.token.handler.address = result.userData.address;

          this.reward.total = new this.$BBN(result.totalData.total);
          this.reward.daily = new this.$BBN(result.totalData.daily);
          this.reward.claimed = new this.$BBN(result.totalData.claimed);
          this.reward.remaining = new this.$BBN(result.totalData.remaining);

          if (this.$store.getters.isBifrost) {
            // bifrostmainnet.remain = real.reward(1,100,000) - (total(81,865,049,858.729876249405918265) - old.remain)
            this.reward.remaining = new this.$BBN('1100000').minus(
              new this.$BBN('81865049858.729876249405918265').minus(
                this.reward.remaining
              )
            );
          } else if (this.$store.getters.isBifrostTestnet) {
            // bifrosttestnet.remain = real.reward(10,000,000) - (total(372,113,862,994.22671022457235578) - old.remain)
            this.reward.remaining = new this.$BBN('10000000').minus(
              new this.$BBN('372113862994.22671022457235578').minus(
                this.reward.remaining
              )
            );
          } else {
            this.reward.daily = new this.$BBN('0');
            this.reward.remaining = new this.$BBN('0');
          }

          this.amount = new this.$BBN(result.userData.earn);

          this.dialog.token.maxAmount['reward.claim'] = this.amount;

          const bifiTokenPrice = this.$curr.getToken('bifi').price;

          const userResult = await this.$store.getters.contract.call(
            'bifi.user',
            this.$store.getters.walletAddress
          );

          this.marketData = [];

          const rewardAssetList = (result.assetList || [])
            .filter((asset) => !this.$getIsDisabledToken(asset.id))
            .sort((pre) => (pre.id === 'btc' ? -1 : 1));
          const userAssetList = (userResult.assetList || [])
            .filter((asset) => !this.$getIsDisabledToken(asset.id))
            .sort((pre) => (pre.id === 'btc' ? -1 : 1));

          for (const tokenKey in rewardAssetList) {
            const token = rewardAssetList[tokenKey] || {};

            const id = token.id || '';
            const price = new this.$BBN(token.price || '1');
            const collateralRate = new this.$BBN(
              ((userAssetList || [])[tokenKey] || {}).collateralRate || '0'
            );

            // reward
            const dailyReward = new this.$BBN(token.daily);
            const claimedReward = new this.$BBN(token.claimed);
            const depositReward = new this.$BBN(token.deposit);
            const borrowReward = new this.$BBN(token.borrow);

            // apy
            const depositAPY = depositReward
              .mul(this.$config.daysPerYear)
              .mul(bifiTokenPrice.div(price))
              .mul('100');
            const borrowAPY = borrowReward
              .mul(this.$config.daysPerYear)
              .mul(bifiTokenPrice.div(price))
              .mul('100');

            // max start
            const maxDepositStart = new this.$BBN('1');
            const maxBorrowStart = new this.$BBN(collateralRate);

            // max amount
            const maxDepositTradeAmount = maxDepositStart.div(
              new this.$BBN('1').minus(collateralRate)
            );
            const maxBorrowTradeAmount = maxBorrowStart.div(
              new this.$BBN('1').minus(collateralRate)
            );

            // max apy
            const maxDepositAPY = maxDepositTradeAmount.mul(depositAPY);
            const maxBorrowAPY = maxBorrowTradeAmount.mul(borrowAPY);

            const maxAPY = maxDepositAPY.plus(maxBorrowAPY);

            const tokenRowData = {
              id,
              title: this.$curr.getToken(id).title || id.toUpperCase(),
              image: this.$curr.getToken(id).image,
              dailyReward,
              claimedReward,
              deposit: {
                reward: depositReward,
                apy: depositAPY,
              },
              borrow: {
                reward: borrowReward,
                apy: borrowAPY,
              },
              maxAPY,
              price,
            };

            this.marketData.push(tokenRowData);
          }
        })
        .catch((error) => {
          // TODO fix metamask header not found
          if (this.$isIgnorableError(error)) {
            return;
          }

          this.$store.dispatch('openAlert', {
            message: 'common.message.dataLoad.error',
            messageArgs: {
              error: `${
                typeof error === 'object'
                  ? error.locale || error.message || error
                  : error
              }`,
            },
            type: 'error',
          });
        })
        .finally(() => this.$store.dispatch('solveProgress', {}));
    },
  },
};
</script>

<style scoped>
.reward {
  color: #000000d9;
}

.reward-description-title {
  font-size: 24px;
  font-weight: bold;
}

.reward-description {
  font-size: 16px;
}

.reward-info-key {
  padding: 30px 0px 20px;
  font-size: 16px;
  text-align: center;
}

.reward-info-value {
  padding-bottom: 30px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.reward-info-description {
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 12px;
  text-align: right;
  opacity: 0.25;
}

.reward-header {
  padding: 40px 0px 16px;
}

.reward-header-title {
  font-size: 20px;
}

.reward-table {
}

.reward-my-info {
  height: unset;
}

.reward-my-info-title {
  font-size: 18px;
  font-weight: bold;
}

.reward-my-info-value {
  text-align: center;
  font-size: 34px;
  font-weight: bold;
  letter-spacing: 1.13px;
}

.reward-my-info-sub-value {
  padding-top: 14px;
  text-align: center;
  font-size: 14px;
  opacity: 0.5;
}

.reward-my-info-description {
  font-size: 14px;
  letter-spacing: 0.39px;
}

/* dark */

#app.dark .reward {
  color: #ffffffd9;
}

/* mobile */

#app.mobile .reward-description-title {
  font-size: 20px;
}

#app.mobile .reward-description {
  font-size: 12px;
}

#app.mobile .reward-header-title {
  padding-bottom: 4px;
  font-size: 14px;
}

#app.mobile .reward-info-key {
  padding: 10px 5px;
  font-size: 12px;
}

#app.mobile .reward-info-value {
  padding: 0px 5px 10px;
  font-size: 14px;
  white-space: wrap;
  word-break: break-all;
}

#app.mobile .reward-info-description {
  position: relative;
  bottom: 0;
  right: 0;
  width: 100%;
  padding: 0px 6px 4px;
  font-size: 10px;
}

#app.mobile .reward-my-info-title {
  font-size: 14px;
}

#app.mobile .reward-my-info-value {
  font-size: 26px;
  letter-spacing: 1px;
}

#app.mobile .reward-my-info-sub-value {
  padding-top: 8px;
  font-size: 12px;
}

#app.mobile .reward-my-info-description {
  font-size: 10px;
}
</style>
